<template>
  <div
    class="ui-collection"
    :class="`--view-${view}`"
  >
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  name: 'ui-collection',
  props: {
    view: {
      type: String,
      required: false,
      default: 'grid',
      validator: value => ['list', 'grid'].indexOf(value) !== -1
    }
  }
};
</script>

<style lang="scss">
.ui-collection {
  &.--view-grid {
    display: flex;
    flex-wrap: wrap;

    & > * {
      flex: 1;
      width: 30%;
      min-width: 280px;
      margin: var(--ui-breathe);
    }
  }

  &.--view-list {
    & > * {
      margin-bottom: var(--ui-breathe);
    }
  }
}
</style>